
.dropdown {
  background-color: var(--color-white);
  min-height: 38px;
  border-radius: var(--border-radius);
}

.dropdown :global(.ant-select-selector) {
  border-radius: var(--border-radius);
  min-height: 38px;
}

.dropdown.small {
  height: 34px;
}
.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  transition: transform 0.2s, top 0.2s;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
}

.dropdown.small .dropdown-icon{
  top: 8px;
}

.dropdown-icon.rotated {
  transform: rotate(180deg);
}

.text {
  padding: 0px 12px;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-md);
  line-height: var(--line-height-md);
  color: var(--color-gray);
  opacity: 1;
  transition: opacity 0.2s;
  transition-delay: 0.2s;
}

.item {
  display: flex;
  align-items: center;
  gap: 12px;
}

div.dropdown:global(.ant-select div.ant-select-selector) {
  border-color: var(--color-gray-30);
}

.dropdown :global(.ant-select-selection-item-content .ant-checkbox-wrapper) {
  display: none;
}

div.dropdown:global(.ant-select div.ant-select-selector .ant-select-selection-placeholder) {
  color: var(--color-gray-60);
}

div.dropdown:global(.ant-select:hover div.ant-select-selector), div.dropdown:global(.ant-select.ant-select-focused div.ant-select-selector) {
  border-color: var(--color-primary-80);
}

.dropdown:global(.ant-dropdown-menu .ant-dropdown-menu-item:hover) {
  background-color: var(--color-gray-10);
}

.dropdown:global(.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected) {
  background-color: var(--color-primary-10);
  color: var(--color-gray);
}

