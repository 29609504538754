.container:global(.ant-select ) {
  
}

.container:global(.ant-select .ant-select-selector) {
  padding-inline-end: 0px;
  border: 1px solid var(--color-gray-30);
  min-height: 38px;
}

.container:global(.ant-select .ant-select-selection-placeholder) {
  color: var(--color-gray-60);
}

.container:global(.ant-select .ant-select-arrow) {
  display: none;
}

.tip {
  color: var(--color-gray-60);
  padding-top: 3px;
}