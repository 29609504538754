.container {
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background-color: #D2D2D2;
}

.container.relevant {
  background-color: var(--color-green);
}

.container.presentation {
  width: 20px;
  height: 20px;
  min-width: 20px;
}