.container {
  flex: 1;
  margin-bottom: 10px;
  min-width: 300px;
}

.video {
  width: 100%;
}

.title {
  padding-bottom: 10px;
}