.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  margin-left: 40px;
}

.drawer :global(.ant-drawer-content) {
  padding: 55px 20px;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-Weight: 600;
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px solid rgba(148, 173, 182, 0.4);
  border-radius: 50%;
  cursor: pointer;
}