.container:global(.ant-segmented) {
  padding: 4px;
  background-color: var(--color-gray-20);
  border-radius: var(--border-radius);
  width: 100%;
}

.container:global(.ant-segmented .ant-segmented-item) {
  flex-grow: 1;
}

.container:global(.ant-segmented .ant-segmented-item .ant-segmented-item-label) {
  min-height: 26px;
  height: 26px;
  border-radius: 5px;
  color: var(--color-gray-80);
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container:global(.ant-segmented .ant-segmented-item.ant-segmented-item-selected .ant-segmented-item-label) {
  color: var(--color-gray);
}

.container.large:global(.ant-segmented .ant-segmented-item .ant-segmented-item-label) {
  min-height: 38px;
  height: 38px;
}