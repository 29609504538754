.active {
  border: 1px solid var(--color-primary);
  padding: 10px 20px 0px;
  border-radius: 10px;
  margin: 20px 0px 40px;
}

.range-form-title {
  text-align: right;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-primary);
}

.button-container:global(.ant-col) {
  display: flex;
  align-items: center;
  justify-content: end;
}

button.add-button {
  margin-top: 30px;
}