.picker:global(.ant-picker) {
  height: 34px;
  border: 1px solid var(--color-gray-30);
}

.picker.large:global(.ant-picker) {
  height: 38px;
}

.picker.large:global(.ant-picker .ant-picker-input input::placeholder) {
  color: var(--color-gray-60);
}

.picker.large:global(.ant-picker .ant-picker-input input) {
  color: var(--color-gray);
}

.picker:global(.ant-picker.ant-picker-focused) {
  height: 34px;
  border: 1px solid var(--color-primary-70);
  box-shadow: none;
}

.picker.large:global(.ant-picker.ant-picker-focused) {
  height: 38px;
}

.picker:global(.ant-picker.ant-picker-focused .ant-picker-active-bar) {
  background: var(--color-primary-70);
}