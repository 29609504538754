.container {
  display: flex;
  gap: 24px;
  overflow: auto;
  padding-bottom: 20px;
}

.column {
  width: 220px;
  min-width: 220px;
}

.head-container .head {
  display: flex;
  align-items: center;
  gap: 8px;
}

.head-container .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.head-container .total {
  height: 22px;
  min-width: 22px;
  border-radius: 50%;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
}

.head-container .line {
  height: 4px;
  border-radius: var(--border-radius);
  width: 100%;
  margin-top: 6px;
}

.head-container.green .status {
  color: var(--color-green-70);
}

.head-container.green .dot, .head-container.green .line {
  background-color: var(--color-green-70);
}

.head-container.yellow .status {
  color: var(--color-yellow-70);
}

.head-container.yellow .dot, .head-container.yellow .line {
  background-color: var(--color-yellow-70);
}

.head-container.red .status {
  color: var(--color-red-70);
}

.head-container.red .dot, .head-container.red .line {
  background-color: var(--color-red-70);
}

.head-container.violet .status {
  color: var(--color-violet-70);
}

.head-container.violet .dot, .head-container.violet .line {
  background-color: var(--color-violet-70);
}

.items-container {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.no-orders {
  text-align: center;
  color: var(--color-gray-60);
}