.title {
  margin-bottom: 10px;
  color: var(--color-gray);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-sm);
  line-height: var(--line-height-md);
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
