span.label {
  text-transform: capitalize;
  background: none !important;
  font-size: smaller;
  border-radius: 20px;
}

.label.error {
  color: var(--color-danger);
  border: 1px solid var(--color-danger);
}

.label.info {
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.label.warning {
  color: var(--color-yellow);
  border: 1px solid var(--color-yellow);
}

.title {
  flex-grow: 0;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-gray);
  margin: 40px 0px 20px;
}