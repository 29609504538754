.options {
  display: flex;
  gap: 4px;
}

.option {
  padding: 2px 8px;
  border-radius: 320px;
  background-color: var(--color-gray-20);
  color: var(--color-gray-80);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  display: flex;
  align-items: center;
  gap: 12px;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px;
  border-radius: var(--border-radius);
  --stroke: var(--color-gray-70);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px;
}

.button:hover {
  background-color: var(--color-gray-20);
}

.tooltip {
  background-color: var(--color-white);
}