.container {
  margin-top: 10px;
}

.divider {
  margin: 10px auto 16px;
}

.header {
  padding: 0px 10px;
}

.header.collapsed {
  padding: 0px;
}

.title.collapsed {
  text-align: center;
  padding: 0;
}