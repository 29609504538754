.details {
  display: flex;
  align-items: center;
}

.divider {
  background-color: var(--color-gray-40);
  height: 16px;
}

.label {
  color: var(--color-gray-60);
}

.value {
  color: var(--color-gray);
}

.line {
  margin: 20px 0px 0px;
}

.barcodes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.barcode {
  background-color: var(--color-gray-20);
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0px 6px;
  border-radius: 320px;
}