.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: 12px;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
}

.mode {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: var(--border-radius);
  background-color: var(--color-yellow-10);
}

.icon-container.auto {
  background-color: var(--color-green-10);
}