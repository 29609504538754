.container {
  background-color: var(--color-white);
  padding: 0px 16px;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
}
.row {
  display: block;
  width: 100%;
}


.row :global(.ant-row) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid var(--color-gray-30);
  padding: 25px 0px;
}

.row :global(.ant-row .ant-col) {
  flex: 1;
  text-align: left;
}

.button {
  max-width: 100px;
  padding: 16px 0px;
  margin-left: auto;
}