.container {
  background-color: var(--color-white);
  border-radius: 10px;
  flex-shrink: 0;
  padding: 32px 30px;
}

.status-cell-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.header {
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 30px;
}

.label {
  font-size: 14px;
  color: var(--color-text);
}

.select-container {
  padding: 20px 0px;
}

.select-container :global(.ant-select) {
  width: 100%;
}

.value {
  color: var(--color-gray);
}

.value > span {
  margin-left: 5px;
}

.tips:global(.ant-col) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.tip {
  display: flex;
  gap: 10px;
  padding-left: 20px;
}

.total-title {
  font-size: 16px;
  color: var(--color-primary);
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
}

.totals {
  text-align: right;
}

.total {
  font-size: 14px;
  color: var(--color-text);
}

.total-value {
  color: var(--color-primary);
  font-weight: 700;
}

.container :global(.ant-table-summary .ant-table-cell) {
  border-bottom: none;
}

.ranges-title {
  font-size: 20px;
  font-weight: 700;
  padding: 30px 0px 20px;
  border-top: 2px solid #E5E6E8;
}

.no-request {
  color: var(--color-primary);
  font-size: 20px;
  font-weight: 700;
  padding: 50px 0px 0px;
  text-align: center;
}

.green {
  color: var(--color-green-80);
}

.red {
  color: var(--color-red-80);
}