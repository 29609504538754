.form {
  padding: 16px 0;
}

.container {
  position: relative;
}

.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding-top: 20px;
}

.buttons > button {
  max-width: 100px;
}

textarea.textarea {
  height: 96px;
}