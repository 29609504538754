.id {
  color: var(--color-gray-70);
}

.title-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tabs {
  margin-top: 10px;
}

.tabs:global(.ant-tabs .ant-tabs-tab) {
  padding: 20px 0px;
  font-size: var(--font-size-md);
}

:global(.rtl) .tabs:global(.ant-tabs .ant-tabs-tab) {
  margin: 0 0 0 32px;
}

.tabs:global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: var(--color-primary-80);
}

.with-total {
  display: flex;
  gap: 10px;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-20);
  color: var(--color-primary-80);
  height: 26px;
  min-width: 26px;
  border-radius: 4px;
}