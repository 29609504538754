.confirmation-container {
  text-align: center;
}

.icon-container {
  max-width: 64px;
  margin: 8px auto 20px;
}

.confirmation-title {
  margin-bottom: 4px;
}