.container:global(.ant-switch) {
  height: 24px;
  background-color: var(--color-gray-30);
}

.container:global(.ant-switch:hover ) {
  background-color: var(--color-gray-40);
}

.container:global(.ant-switch .ant-switch-handle) {
  width: 20px;
  height: 20px;
}


.container:global(.ant-switch.ant-switch-checked) {
  background-color: var(--color-primary-80);
}

.container:global(.ant-switch.ant-switch-checked:hover) {
  background-color: var(--color-primary-90);
}

.container:global(.ant-switch.ant-switch-checked .ant-switch-handle) {
  inset-inline-start: calc(100% - 22px);
}