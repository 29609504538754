.container {
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  padding: 25px 20px;
}

.divider {
  background-color: var(--color-gray-30);
  margin: 0px 0px 0px 16px;
  height: 20px;
}

:global(.rtl) .divider {
  margin: 0px 16px 0px 16px;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}

.activities {
  display: flex;
  align-items: center;
}

.dates {
  display: flex;
  align-items: center;
  gap: 14px;
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
}

.switch-label {
  color: var(--color-gray-90);
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
}

.select {
  min-width: 150px;
}

.range-footer {
  display: flex;
  align-items: center;
  color: var(--color-gray-80);
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  padding: 14px 18px;
}

.range-footer-caption {
  color: var(--color-gray);
}

.range-footer svg {
  margin-right: 5px;
}

.range-footer.rtl svg {
  margin-left: 5px;
  margin-right: 0px;
}