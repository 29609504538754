.upload :global(.ant-upload) {
  width: 100%;
}

.container {
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-container {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: var(--border-radius);
  border: 1px dashed var(--color-gray-30);
  background: var(--color-gray-10);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: var(--color-gray-80);
  margin-bottom: 2px;
}

.rule {
  color: var(--color-gray-60);
}

.browse-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px;
}

.browse-text {
  color: var(--color-primary-80);
}

.image-container {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: 1px solid var(--color-gray-20);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  max-width: 80%;
  max-height: 80%;
}