.item {
  padding: 12px 0px 0px;
  border: 1px solid var(--color-gray-30);
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.item:hover {
  border: 1px solid var(--color-primary-80);
}

.item-line {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0px 12px;
}

.item .item-line:last-child {
  padding-bottom: 12px;
}

.item-line.main {
  margin: 0px 0px 4px 0px;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  gap: 6px;
}

.id {
  color: var(--color-gray);
}

.time {
  color: var(--color-gray-60);
  margin-left: auto;
}

.status {
  padding: 2px 10px;
  text-align: center;
  border-radius: 320px;
}

.status.green {
  background-color: var(--color-green-10);
  color: var(--color-green-70);
}

.status.red {
  background-color: var(--color-red-10);
  color: var(--color-red-70);
}

.status.yellow {
  background-color: var(--color-yellow-10);
  color: var(--color-yellow-70);
}

.status.violet {
  background-color: var(--color-violet-10);
  color: var(--color-violet-70);
}

.status.gray {
  background-color: var(--color-gray-10);
  color: var(--color-gray-70);
}

.order-parts-container {
  --stroke: var(--color-gray-70);
  border-top: 1px solid var(--color-gray-30);
  padding: 8px 12px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.order-parts-container:hover {
  --stroke: var(--color-gray-90);
  background: var(--color-gray-20);
}

.parts-container {
  list-style-type: none;
  padding-left: 0;
}

.parts-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.icon-arrow-right {
  margin-left: auto;
}

.badge {
  border-radius: 320px;
  border: 1px solid var(--color-gray-30);
  background: var(--color-white);
  padding: 2px 8px;
  color: var(--color-gray-80);
}