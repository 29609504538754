.light {
  color: var(--color-gray-60);
  font-weight: var(--font-weight-sm);
}

.divider {
  background-color: var(--color-gray-60);
  height: 16px;
}

.details-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 10px 40px;
}

.details-title {
  color: var(--color-gray-70);
}