.container {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  padding: 20px;
  width: 100%;
}

.head {
  display: flex;
}

.text {
  padding-left: 12px;
}

:global(.rtl) .text {
  padding-right: 12px;
}

.title {
  color: var(--color-gray);
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
}

.subtitle {
  color: var(--color-gray-80);
  font-weight: var(--font-weight-sm);
  font-size: 13px;
  line-height: var(--line-height-md);
}

.actions {
  padding-top: 16px;
}