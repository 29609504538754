.radio :global(.ant-radio-button-wrapper) {
  font-weight: 700;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: 0px 4px 12px 2px rgb(175 173 196 / 10%);
  transition: color 0.3s, background 0.3s, box-shadow 0.3s;
}
.radio :global(.ant-radio-button) {
  display: flex;
  align-items: center;
}
.radio :global(.ant-radio-button-wrapper:first-child) {
  border-left: 1px solid transparent;
}
.radio:global(.ant-radio-group) {
  display: flex;
}

.radio :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
  outline: none;
  box-shadow: none;
}

.radio :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  border: 1px solid var(--color-primary);
}