.container {
  display: flex;
  align-items: center;
  padding-left: 10px;
  line-height: 0px;
}

.title {
  font-size: 14px;
  text-align: left;
  margin-left: 2px;
}

button.button {
  background-color: var(--color-green);
  border: none;
}

button.button:hover {
  background-color: var(--color-green);
  border: none;
  opacity: 0.8;
}

.action {
  margin: 0px 16px 0px 12px;
}

.segmented:global(.ant-segmented ) {
  background-color: var(--color-gray-20);
  padding: 3px;
  border-radius: var(--border-radius);
}

.segmented:global(.ant-segmented .ant-segmented-group .ant-segmented-item) {
  color: var(--color-gray-80);
  background-color: transparent;
  transition: background-color 0.2s;
  border-radius: 5px;
}

.segmented.red:global(.ant-segmented .ant-segmented-group .ant-segmented-thumb) {
  background-color: var(--color-red-60);
  color: var(--color-white);
}

.segmented.green:global(.ant-segmented .ant-segmented-group .ant-segmented-thumb) {
  background-color: var(--color-green-60);
  color: var(--color-white);
}

.segmented:global(.ant-segmented .ant-segmented-group .ant-segmented-item:first-child:not(.ant-segmented-item-selected):hover) {
  color: var(--color-green-80);
}

.segmented:global(.ant-segmented .ant-segmented-group .ant-segmented-item:last-child:not(.ant-segmented-item-selected):hover) {
  color: var(--color-red-80);
}

.segmented:global(.ant-segmented .ant-segmented-group .ant-segmented-item:first-child:not(.ant-segmented-item-selected):hover::after) {
  background-color: transparent;
}

.segmented:global(.ant-segmented .ant-segmented-group .ant-segmented-item:last-child:not(.ant-segmented-item-selected):hover::after) {
  background-color: transparent;
}

.segmented:global(.ant-segmented .ant-segmented-group .ant-segmented-item:first-child.ant-segmented-item-selected) {
  background-color: var(--color-green-60);
  color: var(--color-white);
}

.segmented:global(.ant-segmented .ant-segmented-group .ant-segmented-item:last-child.ant-segmented-item-selected) {
  background-color: var(--color-red-60);
  color: var(--color-white);
}