.container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.required-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-white);
  min-height: 38px;
  padding: 0px 12px;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
}

.create-button-container {
  margin-left: auto;
}
