.container {
  padding: 20px;
  text-align: center;
}

.icon-container {
  background-color: var(--color-green-20);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 0 auto;
}

.icon-inner {
  background-color: var(--color-green-30);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.title {
  margin: 12px 0px 4px;
}