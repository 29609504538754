.container:global(.ant-notification-notice) {
  padding: 0px;
}

.container:global(.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon) {
  display: flex;
  align-items: center;
  padding: 0px 12px;
}

.container:global(.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-message) {
  margin: 0px;
  flex: 1;
}

.container:global(.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-description) {
  display: none;
}

.container:global(.ant-notification-notice .ant-notification-notice-close) {
  top: 9px;
  right: 12px;
}

.container:global(.ant-notification-notice-success) {
  background-color: var(--color-green-10);
  border-radius: 8px;
  border: 1px solid var(--color-green-30);
}

.container:global(.ant-notification-notice-error) {
  background-color: var(--color-red-10);
  border-radius: 8px;
  border: 1px solid var(--color-red-20);
}

.container:global(.ant-notification-notice-warning) {
  background-color: var(--color-yellow-10);
  border-radius: 8px;
  border: 1px solid var(--color-yellow-20);
}

.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 30px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
  border-radius: var(--border-radius);
  color: var(--color-green-70);
  padding: 0px 2px;
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  margin-left: auto;
}

.container:global(.ant-notification-notice-error) .button {
  color: var(--color-red-70);
}