.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.filters {
  margin-bottom: 24px;
}