.icon {
  background-color: var(--color-green-10);
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.large {
  width: 44px;
  height: 44px;
  min-width: 44px;
}

.icon.large svg {
  width: 24px;
  height: 24px;
}

.icon.circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.icon.red {
  background-color: var(--color-red-10);
}

.icon.violet {
  background-color: var(--color-violet-10);
}

.icon.yellow {
  background-color: var(--color-yellow-10);
}

.icon.orange {
  background-color: var(--color-orange-10);
}

.icon.red {
  background-color: var(--color-red-10);
}

.icon.blue {
  background-color: var(--color-primary-10);
}

.icon.orange .inner {
  background-color: var(--color-orange-20);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}