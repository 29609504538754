.form {
  padding: 22px 0px 4px;
}

.category {
  display: flex;
  align-items: center;
}

.optional {
  color: var(--color-gray-60);
}

.create-category-icon {
  margin-top: 4px;
}

.check-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.horizontal :global(.ant-form-item-row) {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}

.horizontal :global(.ant-form-item-row .ant-form-item-control) {
  max-width: 220px;
}

.edit {
  padding: 24px;
}

.edit .horizontal :global(.ant-form-item-row .ant-form-item-control) {
  max-width: 180px;
}

.edit {
  border-top: 1px solid var(--color-gray-30);
  border-bottom: 1px solid var(--color-gray-30);
}