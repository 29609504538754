.container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
}

.skus-list:global(.ant-collapse) .sku-item {
  background-color: var(--color-white);
  border: none;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
}

.sku-item:global(.ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon) {
  margin-top: 10px;
}

.skus-list:global(.ant-collapse) {
  background-color: var(--color-white);
  border: none;
  margin: 0 -15px;
}

.skus-list:global(.ant-collapse .ant-collapse-content) {
  border-top: none;
  background-color: transparent;
}

.skus-list:global(.ant-collapse .ant-collapse-content>.ant-collapse-content-box) {
  padding: 5px 16px;
}

.skus-list :global(.ant-collapse-item.ant-collapse-item-active) {
  background-color: var(--color-gray-10);
  border: 1px solid var(--color-gray-30);
}

.buttons {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: auto;
}
