.header {
  border-bottom: 1px solid var(--color-gray-30);
  padding: 8px 20px;
  height: 62px;
}

.modal :global(.ant-modal-content) {
  padding: 0px;
  height: 100%;
  top: 10px;
}

.modal :global(.ant-modal-body) {
  height: 100%;
}

.modal {
  height: 80%;
}

.media img, .media video {
  border-radius: var(--border-radius);
  border: 2px solid transparent;
  max-width: 100%;
  transition: border-color 0.2s;
  cursor: pointer;
}

.media:hover img, .media:hover video, .media.selected img, .media.selected video {
  border: 2px solid var(--color-primary-80);
}

.content {
  display: flex;
  height: calc(100% - 62px);
}

.list {
  max-width: 230px;
  flex: 1;
  border-right: 1px solid var(--color-gray-30);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.selected-media {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex: 1;
}

.selected-media img, .selected-media video {
  max-width: 100%;
  max-height: 80%;
}

.empty-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
