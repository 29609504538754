.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 200px;
  gap: 10px;
}

.add-button {
  max-width: 100px;
}

.button {
  display: flex;
  align-items: center;
  gap: 12px;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px;
  border-radius: var(--border-radius);
  --stroke: var(--color-gray-70);
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px;
}

.button:hover {
  background-color: var(--color-gray-20);
}

.tooltip {
  background-color: var(--color-white);
}

.icon-button {
  width: 22px;
  max-width: 22px;
  height: 22px;
}