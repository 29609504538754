.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.buttons .button {
  width: 100px;
}

.container :global(.ant-modal-content) {
  padding: 20px;
}