.container {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-30);
  background: var(--color-white);
  padding: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.role-container {
  display: flex;
  align-items: baseline;
}

.button-container button {
  width: 100px;
  color: var(--color-gray-60);
  font-weight: 500;
  margin-left: auto;
}

.description {
  color: var(--color-gray-70);
}

.role-content {
  flex: 1;
}

.button-container {
  flex: 1;
}

