span.tag {
  text-transform: capitalize;
  color: var(--color-secondary);
  background: none;
  font-size: smaller;
  border-radius: 20px;
  border: 1px solid var(--color-secondary);
}

.tag.green {
  color: var(--color-green);
  border: 1px solid var(--color-green);
}

.tag.yellow {
  color: var(--color-yellow);
  border: 1px solid var(--color-yellow);
}

.tag.red {
  color: var(--color-danger);
  border: 1px solid var(--color-danger);
}