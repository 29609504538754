.container {
  display: flex;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 24px;
}

:global(.rtl) .content {
  padding-right: 0px;
  padding-left: 24px;
}

.timeline {
  min-width: 360px;
}