.check:global(.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner) {
  width: 20px;
  height: 20px;
  border-color: var(--color-gray-30);
}

.check:global(.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner) {
  border-color: var(--color-primary-80);
}

.check:global(.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--color-primary-80);
  border-color: var(--color-primary-80);
}

.check:global(.ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--color-primary-90);
  border-color: var(--color-primary-90);
}

.check :global(.ant-checkbox .ant-checkbox-inner::after) {
  margin-left: 1px;
}