.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border: 1px solid var(--color-light-gray);
  padding: 12px 20px;
  border-radius: 16px;
}

.container .icon {
  margin-right: 5px;
}