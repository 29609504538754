.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px solid rgba(148, 173, 182, 0.4);
  border-radius: 50%;
  cursor: pointer;
}

.form {
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form-content {
  flex-grow: 1;
}

.button-cancel:global(.ant-form-item) {
  display: inline-block;
  width: 48%;
  margin-bottom: 0;
}

.button-create:global(.ant-form-item) {
  display: inline-block;
  width: 48%;
  margin-bottom: 0;
  margin-left: 4%;
}

.flex {
  display: flex;
}

.create-range-button {
  margin-bottom: 20px;
}

.ranges-title {
  padding-bottom: 10px;
}

.range-tip {
  color: var(--color-primary);
  font-size: 16px;
  padding-bottom: 30px;
  text-align: right;
}

.range-tip svg {
  margin-right: 10px;
}