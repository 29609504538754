.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.item {
  background-color: var(--color-gray-20);
  color: var(--color-gray-80);
  padding: 0px 10px;
  border-radius: 320px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.list {
  padding-left: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.more-button {
  max-width: 100px;
  white-space: nowrap;
}

.icon {
  display: block;
  margin-bottom: 5px;
}