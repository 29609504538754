.green {
  color: var(--color-green-70);
}

.yellow {
  color: var(--color-yellow-70);
}

.red {
  color: var(--color-red-70);
}

.container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.green .dot {
  background-color: var(--color-green-70);
}

.yellow .dot {
  background-color: var(--color-yellow-70);
}

.red .dot {
  background-color: var(--color-red-70);
}