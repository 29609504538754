.menu:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background-color: var(--color-primary-20);
  border-radius: var(--border-radius);
  color: var(--color-primary-80);
}

.menu:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item), .menu:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu .ant-menu-submenu-title) {
  height: 38px;
}

.menu:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu) {
  padding-bottom: 0;
}

.menu:global(.ant-menu .ant-menu-item), .menu:global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title) {
  padding-left: 10px !important;
  margin: 5px 0px;
  width: 100%;
}

.menu:global(.ant-menu-inline), .menu:global(.ant-menu-vertical) {
  border-right: none;
  border-inline-end: none !important;
}

.menu:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content a) {
  color: var(--color-primary-80);
}

.menu:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after) {
  border-right: none;
}

:global(.ant-menu-inline) {
  border-right: none;
}

.menu :global(.ant-menu-item svg.ant-menu-item-icon), .menu :global(.ant-menu-submenu svg.ant-menu-item-icon) {
  --stroke: var(--color-gray-60);
  width: 20px;
  height: 20px;
}

.menu:global(.ant-menu-inline-collapsed .ant-menu-item svg.ant-menu-item-icon), .menu:global(.ant-menu-inline-collapsed .ant-menu-submenu svg.ant-menu-item-icon) {
  margin-top: 9px;
}

.menu :global(.ant-menu-item .ant-menu-title-content a) {
  color: var(--color-gray-90);
}

.menu :global(.ant-menu-item:not(.ant-menu-item-selected):hover svg.ant-menu-item-icon) {
  --stroke: var(--color-gray-80);
}

.menu :global(.ant-menu-item.ant-menu-item-selected svg.ant-menu-item-icon) {
  --stroke: var(--color-primary-80);
}

.menu :global(.ant-menu-item:not(.ant-menu-item-selected):hover .ant-menu-title-content a) {
  color: var(--color-gray);
}