.container {
  background-color: var(--color-white);
  margin: -24px -24px -24px 0px;
  min-height: calc(100% + 48px);
}

:global(.rtl) .container {
  margin: -24px 0px -24px -24px;
}

.title {
  padding: 24px;
  border-bottom: 1px solid var(--color-gray-30);
}

.content {
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100%;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  padding: 8px;
  height: 54px;
  position: relative;
}

.left-container, .right-container {
  min-width: 140px;
  max-width: 140px;
}

.item .dot {
  width: 7px;
  height: 7px;
  min-width: 7px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 4px);
  border: 1px solid var(--color-gray-40);
  background-color: var(--color-white);
}

.item.left {
  background-color: var(--color-primary-10);
  border: 1px solid var(--color-primary-30);
}

.item.right .dot {
  left: -33px;
}

.item.left .dot {
  right: -35px;
  background-color: var(--color-primary-80);
  border: 3px solid var(--color-primary-20);
  box-sizing: content-box;
}

:global(.rtl) .item.left .dot {
  left: -35px;
  right: auto;
}

.date {
  font-size: var(--font-size-xs);
  font-weight: var(--font-size-sm);
  line-height: var(--line-height-sm);
  color: var(--color-gray-70);
  padding-top: 2px;
}

.gap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-content {
  display: flex;
  gap: 5px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-size-md);
  line-height: var(--line-height-sm);
  color: var(--color-gray-50);
}

.divider-container {
  width: 1px;
  padding: 25px 0px;
}

.divider {
  background-color: var(--color-gray-40);
  width: 100%;
  height: 100%;
}

.status {
  white-space: nowrap;
}