.container {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  margin-bottom: 24px;
  padding: 16px;
  margin-top: 20px;
}

.content {
  display: flex;
  justify-content: stretch;
}

.header {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 10px;
}

.serial-number {
  color: var(--color-gray-60);
}

.item-container {
  display: flex;
  gap: 12px;
  align-items: center;
  flex: 1;
}

.item-container:not(:last-child) {
  border-right: 1px solid var(--color-gray-30);
}

:global(.rtl) .item-container:not(:last-child) {
  border-left: 1px solid var(--color-gray-30);
  border-right: none;
}

.item-container:not(:first-child) {
  padding-left: 16px;
}

:global(.rtl) .item-container {
  padding-right: 16px;
  padding-left: 0px;
}

.icon-container {
  background-color: var(--color-green-10);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius);
}

.content-value {
  display: flex;
  align-items: baseline;
}

.light {
  color: var(--color-gray-60);
}

.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}