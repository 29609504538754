.input:global(.ant-input) {
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 2px rgba(175, 173, 196, 0.1);
  font-size: 14px;
}

.input:global(.ant-input-lg) {
  height: 60px;
}

.input.textarea:global(.ant-input-lg) {
  height: auto;
}

.input:global(.ant-input-affix-wrapper-lg) {
  font-size: 14px;
  padding: 16px 16px;
  height: 60px;
}

.input :global(.ant-input-prefix) {
  margin-right: 9px;
}

.input :global(.ant-input-prefix) {
  margin-right: 9px;
}