.container:global(.ant-switch-checked) {
  background-color: var(--color-secondary);
}

.container:global(.ant-switch-checked .ant-switch-handle) {
  left: calc(100% - 18px - 4px);
}

.container:global(.ant-switch-loading-icon.anticon) {
  top: 5px;
}

.container :global(.ant-switch-handle) {
  width: 18px;
  height: 18px;
  top: 2px;
  left: 3px;
}
.container :global(.ant-switch-handle)::before {
  border-radius: 50%;
}