.container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.status {
  width: 250px;
}

.switch-container {
  margin-left: auto;
}

.switch-item-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hint {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hint-overlay :global(.ant-tooltip-content .ant-tooltip-inner) {
  color: var(--color-gray-80);
}