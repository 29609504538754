.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.auto-mode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.auto-mode-icon {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background-color: var(--color-gray-20);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-mode-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}