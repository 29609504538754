:root {
  /* COLORS */
  --color-primary: #172D6D;
  --color-primary-10: #EFF6FF;
  --color-primary-20: #E6F0FF;
  --color-primary-30: #CCE0FF;
  --color-primary-80: #1D5BD8;
  --color-primary-70: #1F75FF;
  --color-primary-90: #234C9F;

  --color-gray: #24262B;
  --color-gray-10: #F5F7F9;
  --color-gray-20: #EEEFF1;
  --color-gray-30: #DCDEE3;
  --color-gray-40: #C7CAD1;
  --color-gray-50: #AEB2BC;
  --color-gray-60: #9499A5;
  --color-gray-70: #7F8493;
  --color-gray-80: #595E6A;
  --color-gray-90: #363840;

  --color-green-10: #F5FEF6;
  --color-green-20: #DBF0E3;
  --color-green-30: #D6F5DF;
  --color-green-60: #52BC85;
  --color-green-70: #05A35C;
  --color-green-80: #047844;

  --color-red-10: #FEF2F2;
  --color-red-20: #F6C2C2;
  --color-red-60: #E16F6F;
  --color-red-70: #DC2626;
  --color-red-80: #B91C1C;

  --color-yellow-10: #FDF7E8;
  --color-yellow-20: #F6E6C2;
  --color-yellow-70: #FFB81F;
  --color-yellow-80: #E49701;

  --color-orange-10: #FEF7EE;
  --color-orange-20: #FEEDD6;
  --color-orange-70: #EF6E25;
  --color-orange-80: #D35F1D;

  --color-violet-70: #9200EC;
  --color-violet-10: #EDE9FE;

  --color-background: #f6f8fa;
  --color-secondary: #73C9BE;
  --color-light-green: #afece5;
  --color-white: #ffffff;
  --color-text: #00002C;
  --color-light-gray: #efefef;
  --color-danger: #C92C2C;
  
  --color-orange: #eaa61d;
  --color-green: #62ba65;
  --color-black: #000000;

  /* TYPOGRAPHY */
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-h1: 24px;

  --font-weight-sm: 400; 
  --font-weight-md: 500; 
  --font-weight-lg: 600; 

  --line-height-sm: 14px;
  --line-height-md: 20px;
  --line-height-lg: 24px;
  --line-height-h1: 32px;


  --border-radius: 6px;
}



* {
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

html, body, #root {
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

p, h1, h2, h3, h4, h5 {
  margin: 0px;
  color: var(--color-text);
}

a:hover {
  color: inherit;
}

/* FORM & FORM ITEMS */

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item .ant-form-item-label {
  padding: 0px;
  margin: 0px;
}

.ant-form-item .ant-form-item-label label {
  color: var(--color-gray-80);
  height: auto;
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
}

.rtl .ant-dropdown-menu-title-content {
  text-align: right;
}

/* TABS */

div.ant-tabs >.ant-tabs-nav div.ant-tabs-nav-operations {
  display: none;
}

/* DRAWER */

.ant-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}


@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/inter-v12-latin-regular.woff2') format('woff2'),
       url('./fonts/inter-v12-latin-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/inter-v12-latin-500.woff2') format('woff2'),
       url('./fonts/inter-v12-latin-500.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/inter-v12-latin-600.woff2') format('woff2'),
       url('./fonts/inter-v12-latin-600.woff') format('woff');
}

/** Overrides **/
.ant-tooltip .ant-tooltip-inner.ant-tooltip-inner {
  background-color: var(--color-gray);
  font-size: var(--font-size-sm);
  text-align: center;
}