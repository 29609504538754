.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 35px;
  border-bottom: 1px solid var(--color-light-gray);
}

.transfer-id {
  font-size: 24px;
  color: var(--color-primary);
  font-weight: 700;
}

.time {
  font-size: 12px;
}

.container {
  background-color: var(--color-white);
  border-radius: 10px;
  width: 50%;
  flex-shrink: 0;
}