.commands {
  background-color: var(--color-white);
  border: none;
}

.commands:global(.ant-collapse .ant-collapse-item.ant-collapse-item-active) {
  background-color: var(--color-gray-10);
}

.commands:global(.ant-collapse .ant-collapse-item) {
  border-bottom: none;
  border-top: 1px solid var(--color-gray-30);
}

.commands:global(.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-content) {
  background-color: var(--color-gray-10);
}

.commands:global(.ant-collapse .ant-collapse-content) {
  border-top: none;
  padding-bottom: 16px;
}

.commands:global(.ant-collapse .ant-collapse-content .ant-collapse-content-box) {
  padding: 0px 16px;
}

.container {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-30);
}

.header {
  padding: 16px 20px;
}