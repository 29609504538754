.container {
  display: flex;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  padding: 20px;
  align-items: center;
}

.container.no-bottom-radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.container.order-info {
  align-items: flex-start;
  margin-bottom: 20px;
}

.content {
  padding-top: 5px;
}

.info-container {
  flex-grow: 1;
  padding-left: 16px;
}

:global(.rtl) .info-container {
  padding-left: 0px;
  padding-right: 16px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title-container .title {
  color: var(--color-gray);
}

.operations-container {
  min-width: 145px;
}