.fields {
  padding: 24px 24px 0px;
}

.buttons {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.status {
  display: flex;
  justify-content: space-between;
  padding: 10px 24px 20px;
  border-bottom: 1px solid var(--color-gray-30);
}

.statuses-buttons {
  display: flex;
  gap: 8px;
}

.check-container {
  display: flex;
  align-items: center;
  gap: 10px;
}