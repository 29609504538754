.drawer :global(.ant-drawer-body) {
  padding: 0px;
}

.header {
  display: flex;
  align-items: center;
  gap: 15px;
}

.name {
  color: var(--color-gray);
}

.fields {
  border-bottom: 1px solid var(--color-gray-30);
  padding: 20px 24px 0px;
}

.field {
  display: flex;
  margin-bottom: 18px;
  min-height: 25px;
}

.field-name {
  width: 50%;
  min-width: 50%;
  color: var(--color-gray-70);
}

.light {
  color: var(--color-gray-60);
}

.status {
  margin-left: 10px;
  color: var(--color-gray-70);
}

.usages {
  padding: 20px 10px;
}

.usages-title {
  margin-bottom: 20px;
  padding-left: 14px;
}

.usage {
  padding: 14px;
  display: block;
  border-radius: var(--border-radius);
  display: flex;
  gap: 20px;
  --stroke: var(--color-gray-60);
  align-items: center;
}

.usage-content {
  flex: 1;
}

.usage:hover {
  background-color: var(--color-primary-10);
  --stroke: var(--color-primary-80);
}

.usage-date-light {
  color: var(--color-gray-70);
}