.container {
  background-color: var(--color-gray-10);
  padding: 10px;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}