.select :global(.ant-select-arrow) {
  width: 20px;
  height: 20px;
  margin-top: -10px;
}
.select:global(.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder) {
  line-height: 60px;
}
.select:global(.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input) {
  line-height: 60px;
}
.select:global(.ant-select-lg) {
  font-size: 14px;
}
.select:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 8px;
  border: none;
}
.select:global(.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item) {
  line-height: 60px;
}
.select :global(.ant-select-selector) {
  box-shadow: 0px 4px 12px 2px rgba(175, 173, 196, 0.1);
}
.select:global(.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector) {
  height: 60px;
}
.select:global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
  border: none;
}
.select:global(.ant-select-show-search .ant-select-selector .ant-select-selection-search) {
  display: flex;
  align-items: center;
}