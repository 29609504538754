.container {
  display: flex;
  gap: -2px;
}

.input {
  outline: none;
  height: 38px;
  width: 100%;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  border: 1px solid var(--color-gray-30);
  color: var(--color-gray);
  transition: border-color 0.2s;
  padding: 9px 13px;
  max-width: 60px;
  min-width: 60px;
  text-align: center;
}

.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input {
  -moz-appearance: textfield;
}

.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input.with-icon {
  padding-left: 35px;
}

.input::placeholder {
  color: var(--color-gray-60);
}

.input:focus {
  border-color: var(--color-primary-80);
}

.icon-minus {
  border: 1px solid var(--color-gray-30);
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.icon-plus {
  border: 1px solid var(--color-gray-30);
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}