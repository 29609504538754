.container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.button-container {
  margin-left: auto;
}

:global(.rtl) .button-container {
  margin-right: auto;
  margin-left: 0px;
}

.cart-amount {
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border-radius: 50%;
  color: var(--color-primary-80);
  margin-bottom: 3px;
}
