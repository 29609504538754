.drawer {
  position: static;
}

button.button {
  display: inline-flex;
  align-items: center;
}

.page-header {
  padding: 0;
  flex: 1;
}

.header {
  display: flex;
  align-items: center;
}

/* Compare */

.compare-container {
  margin-bottom: 40px;
}

.headline {
  font-size: 18px;
  font-weight: 500;
}

.compare-head {
  display: flex;
  justify-content: space-between;
}

.code {
  margin-bottom: 0;
  font-size: 16px;
}

.result {
  margin: 0 10px 0px 0px;
  font-size: 16px;
}

.alert {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.col {
  background-color: var(--color-white);
}

.col.with-gap {
  padding-left: 20px;
  position: relative;
}

.col.with-gap::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 50px;
  height: calc(100% - 2px);
  background: linear-gradient(270deg, #032b81 -19.23%, #dde4f3 78%);
  border-top-left-radius: 20px;
}

.col.with-gap > div {
  position: relative;
  height: 100%;
  background: var(--color-white);
}