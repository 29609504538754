
.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.container {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid var(--color-gray-20);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container img {
  max-width: 80%;
  max-height: 80%;
}