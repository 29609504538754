.container :global(.PhoneInput) {
  display: flex;
  height: 34px;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.container.focused :global(.PhoneInput) {
  border: 1px solid var(--color-primary-80);
}

.container :global(.PhoneInput .PhoneInputCountry) {
  width: 68px;
  background-color: var(--color-gray-30);
  padding: 0px 15px 0px 10px;
  margin: 0px;
}

.container :global(.PhoneInput .PhoneInputInput) {
  height: 100%;
  border: none;
  padding-left: 8px;
}

.container :global(.PhoneInput .PhoneInputInput:focus) {
  outline: none;
}

.container :global(.PhoneInput .PhoneInputCountryIcon) {
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: none;
}
.container :global(.PhoneInput .PhoneInputCountrySelectArrow) {
  width: 14px;
  height: 9.5px;
  margin-bottom: 4px;
}

.container :global(.PhoneInput .PhoneInputCountryIcon .PhoneInputCountryIconImg) {
  width: 22px;
}
