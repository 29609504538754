.container {
  margin-top: 32px;
}

.button-container {
  margin-top: 24px;
  text-align: center;
}

button.button {
  max-width: 400px;
}

.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 12px;
  font-size: var(--font-size-md);
  padding: 3px 10px;
  color: var(--color-white);
  margin-left: 10px;
  background-color: var(--color-secondary);
  user-select: none;
  vertical-align: middle;
}

.privileges {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}