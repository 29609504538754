.container {
  padding: 0px 20px 0px 0px;
  --stroke: var(--color-gray-70);
}

:global(.rtl) .container {
  padding: 0px 0px 0px 20px;
}

.container:hover {
  --stroke: var(--color-gray);
}

.menu :global(.ant-dropdown-menu-item) {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  --stroke: var(--color-gray-70);
}

.menu :global(.ant-dropdown-menu-item:hover) {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  --stroke: var(--color-gray);
}