.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
  width: 38px;
  height: 38px;
  border-radius: var(--border-radius);
  min-width: 38px;
  transition: transform 0.2s;
}

.button.white {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
}

.button:hover {
  background-color: rgba(25, 34, 48, 0.06);
}

.button.white:hover {
  background-color: var(--color-gray-10);
}