.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 20px;
}

.buttons .button {
  width: 100px;
}
