.container {
  background-color: var(--color-gray-20);
  color: var(--color-gray);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-sm);
  border-radius: 4px;
  width: 112px;
  min-width: 112px;
  height: 40px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  outline: none;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 0.2s, border 0.2s;
}

.gap {
  width: 112px;
  min-width: 112px;
}

.container.box {
  background: url("../../../assets/box-pattern.png");
  background-size: cover;
  pointer-events: none;
}

.container.not-clickable {
  pointer-events: none;
  cursor: initial;
}

.station {
  font-weight: var(--font-weight-lg);
}

.container:hover {
  border: 1px solid var(--color-primary-80);
  background-color: white;
}

.container.selected {
  border: 1px solid var(--color-primary-80);
  background-color: white;
}

.container.empty {
  background-color: var(--color-gray-10);
  border: 1px solid var(--color-gray-10);
  cursor: initial;
  pointer-events: none;
}

.buffer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.buffer-text {
  color: var(--color-primary-80);
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-primary-80);
}