section.container {
  flex-direction: row;
  height: 100%;
}

.content {
  overflow: auto;
  height: 100vh;
  padding: 24px;
  background-color: var(--color-gray-10);
}

header.header {
  top: 0;
  position: sticky;
  padding: 10px 24px;
  height: 60px;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-30);
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: space-between;
}

.container :global(.ant-layout-sider-light .ant-layout-sider-trigger) {
  display: none;
}