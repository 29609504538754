.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-30);
  background: var(--color-white);
  padding: 16px 20px 20px;
}

.form {
  margin-top: 16px;
}

.roles {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}