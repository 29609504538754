.container:global(.ant-collapse) {
  border-radius: var(--border-radius);
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none;
}

.container:global(.ant-collapse .ant-collapse-item.ant-collapse-item-active) {
  background-color: var(--color-gray-10);
}

.container:global(.ant-collapse .ant-collapse-item) {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  border-top: none;
}
.container:global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
  align-items: center;
}

.container:global(.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-content) {
  background-color: var(--color-gray-10);
}

.container:global(.ant-collapse .ant-collapse-content) {
  padding-bottom: 16px;
}

.container:global(.ant-collapse .ant-collapse-content .ant-collapse-content-box) {
  padding: 0px 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 5px;
}

.header-products-label {
  color: var(--color-gray-60);
}

.operations {
  display: flex;
  padding-left: 5px;
}

.operation-icon-button {
  --stroke: var(--color-gray-70);
}

.operation-icon-button:hover {
  --stroke: var(--color-gray-90);
}