.manual {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.user, .time {
  display: flex;
  align-items: center;
  gap: 6px;
}