.delete-user-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.delete-user-description {
  text-align: center;
}

.icon {
  cursor: pointer;
}

.table-container:global(.ant-table-wrapper .ant-table-row-expand-icon-cell) {
  padding: 16px 16px 10px;
}
