.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.type-container {
  width: 120px;
}

.date-container {
  width: 130px;
}

.time-container {
  width: 120px;
}

.duration-container {
  width: 150px;
}

.form-content {
  background-color: var(--color-gray-10);
  border-radius: var(--border-radius);
  padding-bottom: 4px;
  margin-bottom: 16px;
}

.form-row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 14px 0px;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0px 14px;
}

.textarea-container {
  padding: 0px 14px 0px;
}

.button {
  max-width: 100px;
}

.add-button {
  max-width: 115px;
  margin-right: auto;
}