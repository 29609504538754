.container {
  display: flex;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-30);
  background: var(--color-white);
  padding: 6px 20px 16px;
  align-items: flex-start;
  gap: 40px;
}

.users {
  display: flex;
  flex-direction: column;
}

.user {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-20);
  padding: 5px 0px;
}

.user-name {
  flex: 1;
  width: 100%;
}

.container .user button {
  width: 100px;
  color: var(--color-gray-60);
  font-weight: 500;
  margin-left: auto;
}

.description {
  color: var(--color-gray-70);
}

.role-container {
  flex: 1;
  padding-top: 10px;
}

.users-container {
  flex: 1;
}

.container .add-button {
  width: auto;
  margin: 5px 0px 0px -10px;
}
