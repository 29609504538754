.container {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  display: flex;
  height: 100%;
}

.streams {
  padding: 20px;
  flex: 1;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.filters-container {
  min-width: 265px;
  width: 265px;
  border-left: 1px solid var(--color-gray-30);
  position: relative;
  transition: width 0.2s;
}

.filters-container.collapsed {
  min-width: 30px;
  width: 30px;
}

.collapse-icon {
  transform: rotate(180deg);
  transition: transform 0.2s;
}

.collapse-icon.collapsed {
  transform: rotate(0deg);
}

.filters-container.collapsed .filters-header, .filters-container.collapsed .cameras {
  opacity: 0;
}

.filters-header {
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-30);
}

.collapse-button {
  box-shadow: 0px 4px 12px 2px rgba(175, 173, 196, 0.1);
  border-radius: 50%;
  position: absolute;
  background-color: white;
  left: -20px;
  top: 12px;
}

.cameras {
  padding: 0 10px;
  list-style-type: none;
  margin-left: 0px;
}

.camera-button {
  background: none;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: var(--border-radius);
  margin-bottom: 5px;
  padding: 6px 10px;
}

.camera-button:not(:disabled):hover {
  background-color: var(--color-primary-10);
}

.camera-button:disabled {
  background-color: var(--color-gray-10);
}

 
