.title {
  margin-bottom: 20px;
}

.key-type-description {
  color: var(--color-gray-60);
}

:global(.ant-dropdown-trigger) .key-type-description {
  display: none;
}