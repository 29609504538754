
.create-category-icon {
  margin-top: 4px;
}

.editable {
  margin-left: -10px;
}

.drawer :global(.ant-drawer-header) {
  border-bottom: none;
  padding-bottom: 0px;
}

.drawer :global(.ant-drawer-body) {
  padding: 0px;
}

.tabs, .tabs :global(.ant-tabs-content-holder), .tabs :global(.ant-tabs-content), .tabs :global(.ant-tabs-tabpane) {
  height: 100%;
}

.tabs :global(.ant-tabs-tab) {
  flex: 1;
  display: flex;
  justify-content: center;
}

.tabs :global(.ant-tabs-nav-list) {
  width: 100%;
}