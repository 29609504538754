.form {
  padding: 22px 0px 4px;
}

.check-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-label {
  color: var(--color-gray-90);
  margin: 5px 0px 0px -35px;
}

.select:global(.ant-select .ant-select-selector) {
  padding-inline-end: 0px;
  border: 1px solid var(--color-gray-30);
  min-height: 38px;
}

.select:global(.ant-select .ant-select-selection-placeholder) {
  color: var(--color-gray-60);
}

.select:global(.ant-select .ant-select-arrow) {
  display: none;
}