.container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.status {
  width: 250px;
}

.type {
  width: 250px;
}

.switch-container {
  margin-left: auto;
}

.switch-item-container {
  display: flex;
  align-items: center;
  gap: 8px;
}