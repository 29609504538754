.textarea {
  outline: none;
  height: 38px;
  width: 100%;
  font-weight: var(--font-weight-sm);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
  color: var(--color-gray);
  transition: border-color 0.2s;
  padding: 9px 13px;
}

.textarea::placeholder {
  color: var(--color-gray-60);
}

.textarea:focus {
  border-color: var(--color-primary-80);
}