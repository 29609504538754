.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary-80);
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
  height: 38px;
  border-radius: var(--border-radius);
  transition: transform 0.2s;
  color: var(--color-white);
  padding: 0px 10px;
  width: 100%;
  font-weight: var(--font-weight-lg);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  display: flex;
  gap: 8px;
  align-items: center;
}

.button:disabled {
  background: var(--color-gray-50);
}

.button.small {
  height: 34px;
}

.icon-right {
  padding-top: 3px;
}

.button:not(:disabled):hover {
  background-color: var(--color-primary-90);
}

.button.default {
  color: var(--color-gray-80);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-20);
}

.button.default:disabled {
  background-color: var(--color-gray-10);
  color: var(--color-gray-70);
  font-weight: var(--font-weight-md);
}

.button:not(:disabled).default:hover {
  color: var(--color-gray);
  background-color: var(--color-gray-10);
  border: 1px solid var(--color-gray-30);
}

.button:not(:disabled).transparent:hover {
  background-color: var(--color-gray-10);
}

.button:disabled {
  cursor: initial;
}

.button.transparent {
  background-color: transparent;
  color: var(--color-primary-80);
}

.button.danger {
  background-color: transparent;
  border: 1px solid var(--color-red-20);
  color: var(--color-red-70);
}

.button.danger-fluid {
  background-color: var(--color-red-70);
  color: var(--color-white);
}

.button.danger:hover {
  background-color: var(--color-red-10);
}
